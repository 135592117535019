<template>
  <div style="text-align: center;">
    <h3>Deconectare...</h3>
  </div>
</template>

<script>
export default {
  created() {
    this.logout()
  }
}
</script>